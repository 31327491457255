import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const DocumentsCell = ({ value: documents = [], onClick, onClickDeleteDocument }) => {
  return (
    <div className="document-links">
      {documents.map((doc, index) => (
        <p key={`doc_${index}`}>
          <span
            className="link"
            key={`doclink_${index}`}
            onClick={(event) => {
              event.stopPropagation();
              onClick(doc.id);
            }}>
            {doc.name}
          </span>
          {onClickDeleteDocument && doc.can_delete && (
            <span className="delete-doc" onClick={() => onClickDeleteDocument(doc.id)}>
              🗑
            </span>
          )}
        </p>
      ))}
    </div>
  );
};

DocumentsCell.propTypes = {
  onClick: PropTypes.func,
  onClickDeleteDocument: PropTypes.func,
  value: PropTypes.array,
};

export default DocumentsCell;
