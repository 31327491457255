import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DocumentsCell from '../DocumentsCell';

import './DocumentsList.scss';

const DocumentsList = ({ documents, onClickDocument, onClickDeleteDocument }) => {
  const [accidentPacket, setAccidentPacket] = useState();
  const [images, setImages] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [videos, setVideos] = useState([]);

  const imageExtensions = [
    'jpg',
    'jpeg',
    'jpe',
    'jif',
    'jfif',
    'jfi',
    'png',
    'gif',
    'webp',
    'tiff',
    'tif',
    'psd',
    'raw',
    'arw',
    'bmp',
    'heif',
    'heic',
  ];

  const videoExtensions = ['mp4', 'mov', 'wmv', 'avi', 'avchd', 'flv', 'f4v', 'swf', 'mkv', 'webm'];

  useEffect(() => {
    // The accident packet should be the only un-deletable file
    const packet = documents.find((doc) => !doc.can_delete);
    setAccidentPacket(packet);
    // Image files determined purely by extension, which should suffice
    const imageFiles = documents.filter(({ name }) =>
      imageExtensions.includes(name.toLowerCase().substring(name.lastIndexOf('.') + 1))
    );
    setImages(imageFiles);
    const videoFiles = documents.filter(({ name }) =>
      videoExtensions.includes(name.toLowerCase().substring(name.lastIndexOf('.') + 1))
    );
    setVideos(videoFiles);
    // Other files is everything else
    const foundDocumentIds = [packet, ...imageFiles, ...videoFiles].map((doc) => doc.id);
    const remainingFiles = documents.filter(({ id }) => !foundDocumentIds.includes(id));
    setOtherFiles(remainingFiles);
  }, [documents]);

  return (
    <div className="documents-list">
      {accidentPacket && (
        <div className="documents-list__section">
          <h3>Accident Packet</h3>
          <DocumentsCell
            value={[accidentPacket]}
            onClick={onClickDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />
        </div>
      )}
      {otherFiles.length > 0 && (
        <div className="documents-list__section">
          <h3>Other Files</h3>
          <DocumentsCell
            value={otherFiles}
            onClick={onClickDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />
        </div>
      )}
      {images.length > 0 && (
        <div className="documents-list__section">
          <h3>Images</h3>
          <DocumentsCell
            value={images}
            onClick={onClickDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />
        </div>
      )}
      {videos.length > 0 && (
        <div className="documents-list__section">
          <h3>Videos</h3>
          <DocumentsCell
            value={videos}
            onClick={onClickDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />
        </div>
      )}
    </div>
  );
};

DocumentsList.propTypes = {
  documents: PropTypes.array,
  onClickDocument: PropTypes.func,
  onClickDeleteDocument: PropTypes.func,
};

export default DocumentsList;
