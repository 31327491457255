import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownList } from 'react-widgets';
import { DateTimePicker } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';
import moment from 'moment-timezone';

import DocumentsList from './DocumentsList';

import './index.scss';

moment.locale('en');
momentLocalizer();

const SafetyForm = ({
  disableButtons = false,
  mode = SafetyForm.modes.add,
  onClickCancel,
  onClickDeleteDocument,
  onClickDocument,
  onClickSubmit,
  opsUsers,
  report,
  reportTypes,
  resolutions,
}) => {
  const [reportType, setReportType] = useState();
  const [employee, setEmployee] = useState();
  const [incidentDate, setIncidentDate] = useState();
  const [incidentTime, setIncidentTime] = useState();
  const [resolution, setResolution] = useState(
    resolutions.find((r) => r.resolution === 'unresolved')
  );
  const [accidentPacket, setAccidentPacket] = useState([]);
  const [files, setFiles] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isEditMode, setIsEditMode] = useState();

  useEffect(() => {
    setIsEditMode(mode === SafetyForm.modes.edit);
    if (report) {
      setIncidentDate(moment(report.incident_date).toDate());
      setIncidentTime(moment(report.incident_time, 'HH:mm:ss').toDate());
      setReportType(report.report_type);
      setEmployee(report.user.user_id);
    }
  }, []);

  useEffect(() => {
    if (isEditMode) {
      return;
    }
    const disabled =
      !reportType || !employee || !incidentDate || !incidentTime || accidentPacket.length === 0;
    setSubmitDisabled(disabled);
  }, [reportType, employee, incidentDate, incidentTime, accidentPacket]);

  useEffect(() => {
    if (!isEditMode) {
      return;
    }
    const disabled = files.length === 0 && resolution.resolution === report.resolution.resolution;
    setSubmitDisabled(disabled);
  }, [files, resolution]);

  const createSafetyReport = (event) => {
    event.preventDefault();
    onClickSubmit({
      accidentPacket,
      employee,
      files,
      incidentDate,
      incidentTime,
      reportType,
      resolution,
    });
  };

  const handleAccidentPacketSelect = (event) => {
    event.preventDefault();
    setAccidentPacket(Object.values(event.target.files));
  };

  const handleFileSelect = (event) => {
    event.preventDefault();
    setFiles(Object.values(event.target.files));
  };

  const handleClickDocument = (documentId) => {
    onClickDocument && onClickDocument(report.id, documentId);
  };

  const handleDeleteDocument = (documentId) => {
    onClickDeleteDocument && onClickDeleteDocument(report.id, documentId);
  };

  const sectionClass = isEditMode ? 'safety-form__form-section' : 'safety-form__form-section add';

  return (
    <Fragment>
      <h2>{isEditMode ? 'Report Details' : 'Create new Report'}</h2>
      <p>
        {isEditMode
          ? 'You can attach more files to a safety report, or set its resolution.'
          : 'Fields with an asterisk (*) are required.'}
      </p>
      <div className="safety-form-container">
        <form className="safety-form" onSubmit={createSafetyReport}>
          <div className={sectionClass}>
            <label>Incident date</label>
            <DateTimePicker
              className="safety-form__form-picker"
              defaultValue={incidentDate}
              disabled={isEditMode}
              onChange={(value) => setIncidentDate(value)}
              time={false}
              value={incidentDate}
            />
          </div>
          <div className={sectionClass}>
            <label>Incident time</label>
            <DateTimePicker
              className="safety-form__form-picker"
              date={false}
              onChange={(value) => setIncidentTime(value)}
              time={true}
              value={incidentTime}
              disabled={isEditMode}
            />
          </div>
          <div className={sectionClass}>
            <label>Report type</label>
            <DropdownList
              className="safety-form__form-picker"
              data={reportTypes}
              datakey="id"
              disabled={isEditMode}
              onChange={(value) => setReportType(value)}
              placeholder="Click to select Report Types"
              textField="name"
              value={reportType}
              valueField="id"
            />
          </div>
          <div className={sectionClass}>
            <label>Employee</label>
            <DropdownList
              className="safety-form__form-picker"
              data={opsUsers}
              disabled={isEditMode}
              onChange={(value) => setEmployee(value)}
              placeholder="Select an Employee"
              textField="fullname"
              value={employee}
              valueField="id"
            />
          </div>
          <div className={sectionClass}>
            <label>Resolution</label>
            <DropdownList
              className="safety-form__form-picker"
              data={resolutions}
              dataField="resolution"
              onChange={(value) => setResolution(value)}
              placeholder="Click to select Resolutions"
              textField="name"
              value={resolution}
            />
          </div>
          {!isEditMode && (
            <div className={sectionClass}>
              <label>Accident Packet</label>
              <input type="file" onChange={handleAccidentPacketSelect} />
            </div>
          )}
          <div className={'safety-form__form-section'}>
            <label>Additional Documents</label>
            <input type="file" onChange={handleFileSelect} multiple />
          </div>
          <div className="safety-form__buttons">
            <button onClick={createSafetyReport} disabled={submitDisabled || disableButtons}>
              Submit
            </button>
            <button onClick={onClickCancel} disabled={disableButtons}>
              Cancel
            </button>
          </div>
        </form>
        {isEditMode && (
          <DocumentsList
            documents={report.documents}
            onClickDocument={handleClickDocument}
            onClickDeleteDocument={handleDeleteDocument}
          />
        )}
      </div>
    </Fragment>
  );
};

SafetyForm.modes = {
  add: 'add',
  edit: 'edit',
};

SafetyForm.propTypes = {
  disableButtons: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(SafetyForm.modes)),
  onClickCancel: PropTypes.func,
  onClickDeleteDocument: PropTypes.func,
  onClickDocument: PropTypes.func,
  onClickSubmit: PropTypes.func,
  opsUsers: PropTypes.array,
  report: PropTypes.object,
  reportTypes: PropTypes.array.isRequired,
  resolutions: PropTypes.array.isRequired,
};

export default SafetyForm;
