import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import './index.scss';

const fullName = (firstName, lastName) => `${firstName} ${lastName}`;

const EmployeeCell = ({ value: employee }) => {
  return employee ? (
    <Fragment>{fullName(employee.first_name, employee.last_name)}</Fragment>
  ) : (
    <div />
  );
};

EmployeeCell.propTypes = {
  value: PropTypes.object,
};

const StyledAutocomplete = withStyles({
  root: {
    background: 'none',
    border: 0,
    color: 'white',
    height: 40,
  },
  paper: {
    fontSize: 'small',
  },
  input: {
    border: 'none !important',
    fontSize: 'smaller !important',
  },
})(Autocomplete);

const SafetyReportsTable = ({
  safetyReports = [],
  onClickReport,
  opsUsers = [],
  reportTypes,
  resolutions,
}) => {
  return (
    <ReactTable
      className="-striped safety-reports-table"
      filterMethod
      defaultSorted={[
        {
          id: 'incident_date',
          desc: true,
        },
      ]}
      data={safetyReports}
      filterable={true}
      getTdProps={(state, row) => {
        return {
          onClick: (e, handleOriginal) => {
            onClickReport && onClickReport(row.original);
            handleOriginal && handleOriginal();
          },
        };
      }}
      columns={[
        {
          Header: 'Incident Date',
          accessor: 'incident_date',
          filterMethod: (column, { incident_date: incidentDate }) =>
            incidentDate.includes(column.value),
          maxWidth: 120,
        },
        {
          Header: 'Incident Time',
          accessor: 'incident_time',
          filterable: false,
          maxWidth: 120,
        },
        {
          Header: 'Report Type',
          accessor: 'report_type.name',
          maxWidth: 150,
          filterMethod: ({ value }, { 'report_type.name': reportType }) =>
            !value || reportType.toLowerCase().startsWith(value.toLowerCase()),
          Filter: (props) => (
            <StyledAutocomplete
              onChange={(event, value) => {
                // eslint-disable-next-line react/prop-types
                props.onChange(value ? value.name : undefined);
              }}
              options={reportTypes}
              getOptionLabel={({ name }) => name}
              getOptionSelected={(option, value) => {
                return option.name.toLowerCase() === value.name.toLowerCase();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  onChange={(event) => {
                    // eslint-disable-next-line react/prop-types
                    props.onChange(event.target.value);
                  }}
                />
              )}
            />
          ),
        },
        {
          Header: 'Employee',
          accessor: 'user',
          Cell: (props) => <EmployeeCell {...props} />,
          filterMethod: ({ value: name }, { user }) => {
            return (
              !name ||
              fullName(user.first_name, user.last_name).toLowerCase().includes(name.toLowerCase())
            );
          },
          Filter: (props) => (
            <StyledAutocomplete
              onChange={(event, value) => {
                // eslint-disable-next-line react/prop-types
                props.onChange(value ? value.fullname : undefined);
              }}
              options={opsUsers}
              getOptionLabel={({ fullname }) => fullname}
              getOptionSelected={(option, value) => {
                return option.fullname.toLowerCase() === value.fullname.toLowerCase();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  onChange={(event) => {
                    // eslint-disable-next-line react/prop-types
                    props.onChange(event.target.value);
                  }}
                />
              )}
            />
          ),
        },
        {
          Header: 'Documents',
          accessor: 'documents',
          filterable: false,
          maxWidth: 200,
          Cell: (props) => {
            // eslint-disable-next-line react/prop-types
            return <p>{`${props.original.documents.length} documents`}</p>;
          },
        },
        {
          Header: 'Resolution',
          id: 'resolution',
          accessor: ({ resolution }) => resolution,
          Cell: (props) => {
            // eslint-disable-next-line react/prop-types
            return <p>{props.row.resolution.name}</p>;
          },
          filterable: true,
          maxWidth: 200,
          filterMethod: ({ value = 'all' }, { resolution: { resolution } }) => {
            return value === 'all' || resolution.toLowerCase().startsWith(value.toLowerCase());
          },
          Filter: (props) => (
            <StyledAutocomplete
              onChange={(event, value) => {
                // eslint-disable-next-line react/prop-types
                props.onChange(value ? value.resolution : undefined);
              }}
              options={resolutions}
              getOptionLabel={({ name }) => name}
              getOptionSelected={(option, value) => {
                return option.resolution === value.resolution;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  onChange={(event) => {
                    // eslint-disable-next-line react/prop-types
                    props.onChange(event.target.value);
                  }}
                />
              )}
            />
          ),
        },
      ]}></ReactTable>
  );
};

SafetyReportsTable.propTypes = {
  safetyReports: PropTypes.array,
  onClickReport: PropTypes.func,
  opsUsers: PropTypes.array,
  reportTypes: PropTypes.array,
  resolutions: PropTypes.array,
};

export default SafetyReportsTable;
