import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DropdownList } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';
import moment from 'moment-timezone';

import {
  getSafetyReportDocumentUrl,
  deleteSafetyReportDocument,
  postSafetyReport,
  attachSafetyReportDocuments,
  patchSafetyReport,
} from '../../api';
import { getOpsUsers } from '../../state/users';
import { setSelectedZone } from '../../state/selected_zone';
import { getSafetyReports } from '../../state/safety_reports';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import RazorLoader from '../../Components/Loader';

import './index.scss';
import SafetyReportsTable from '../../Components/SafetyReportsTable';
import SafetyForm from '../../Components/SafetyReportsForm';
import AlertDialog from '../../Components/AlertDialog';

moment.locale('en');
momentLocalizer();

const resolutions = [
  {
    resolution: 'at_fault',
    name: 'At Fault',
  },
  {
    resolution: 'not_at_fault',
    name: 'Not At Fault',
  },
  {
    resolution: 'unresolved',
    name: 'Unresolved',
  },
];

const reportTypes = [
  { id: 'accident', name: 'Accident' },
  { id: 'injury', name: 'Injury' },
];

const Safety = ({ actions, opsUsers, safetyReports, selectedZoneId, zones }) => {
  const [showCreateReportUI, setShowCreateReportUI] = useState(false);
  const [zoneName, setZoneName] = useState();
  const [selectedReport, setSelectedReport] = useState();
  const [documentToDelete, setDocumentToDelete] = useState();
  const [apiError, setApiError] = useState();
  const [apiCallComplete, setApiCallComplete] = useState(true);

  useEffect(() => {
    if (!selectedZoneId) {
      return;
    }
    actions.getOpsUsers(selectedZoneId);
    actions.getSafetyReports({ zoneId: selectedZoneId });
    const selectedZoneName = zones.find(({ id }) => id === selectedZoneId).name;
    setZoneName(selectedZoneName);
  }, [selectedZoneId]);

  const showSafetyReportForm = () => setShowCreateReportUI(true);

  const createSafetyReport = async ({
    accidentPacket,
    employee,
    files,
    incidentDate,
    incidentTime,
    reportType,
    resolution,
  }) => {
    try {
      setApiCallComplete(false);
      const report = await postSafetyReport({
        accidentPacket,
        employee: employee.id,
        incidentDate: moment(incidentDate).format('YYYY-MM-DD'),
        incidentTime: moment(incidentTime).format('HH:mm'),
        reportType: reportType.id,
        resolution: resolution.resolution,
        zoneId: selectedZoneId,
      });
      if (files.length > 0) {
        await attachSafetyReportDocuments({ id: report.id, files });
      }
      setShowCreateReportUI(false);
      await actions.getSafetyReports({ zoneId: selectedZoneId });
    } catch (error) {
      setApiError(error);
    } finally {
      setApiCallComplete(true);
    }
  };

  const updateSafetyReport = async ({ resolution, files }) => {
    try {
      setApiCallComplete(false);
      if (resolution.resolution !== selectedReport.resolution.resolution) {
        await patchSafetyReport({ id: selectedReport.id, resolution: resolution.resolution });
      }
      if (files.length > 0) {
        await attachSafetyReportDocuments({ id: selectedReport.id, files });
      }
      setSelectedReport(null);
      await actions.getSafetyReports({ zoneId: selectedZoneId });
    } catch (error) {
      setApiError(error);
    } finally {
      setApiCallComplete(true);
    }
  };

  const cancelCreateSafetyReport = (event) => {
    event.preventDefault();
    setShowCreateReportUI(false);
    setSelectedReport(null);
  };

  const onClickDocument = (reportId, documentId) => {
    getSafetyReportDocumentUrl({ safetyReportId: reportId, documentId })
      .then((response) => window.open(response.public_url))
      .catch((error) => console.error(error));
  };

  const onClickDeleteDocument = (reportId, documentId) => {
    setDocumentToDelete({ reportId, documentId });
  };

  const onConfirmDeleteDocument = () => {
    deleteSafetyReportDocument({
      safetyReportId: documentToDelete.reportId,
      documentId: documentToDelete.documentId,
    })
      .then((result) => {
        setSelectedReport(result);
        setDocumentToDelete(null);
      })
      .then(() => actions.getSafetyReports({ zoneId: selectedZoneId }))
      .catch((error) => {
        console.error(error);
        setApiError(error);
      });
  };

  const onConfirmCancelDocument = () => setDocumentToDelete(null);

  const onClickReport = (report) => setSelectedReport(report);

  return (
    <AuthenticatedPage>
      <div className="page-title">Safety Reports</div>
      <div className="zone-picker">
        <h3>Select Zone</h3>
        <DropdownList
          className="vehicles_container__select select-zone"
          data={zones}
          onChange={({ id }) => actions.setSelectedZone(id)}
          placeholder="Select a Zone"
          textField="name"
          value={selectedZoneId}
          valueField="id"
        />
      </div>
      {selectedZoneId &&
        (selectedReport || showCreateReportUI ? (
          <SafetyForm
            disableButtons={!apiCallComplete}
            mode={selectedReport ? SafetyForm.modes.edit : SafetyForm.modes.add}
            onClickCancel={cancelCreateSafetyReport}
            onClickDeleteDocument={onClickDeleteDocument}
            onClickDocument={onClickDocument}
            onClickSubmit={selectedReport ? updateSafetyReport : createSafetyReport}
            opsUsers={opsUsers}
            report={selectedReport}
            reportTypes={reportTypes}
            resolutions={resolutions}
          />
        ) : (
          <Fragment>
            <h2>
              {`Safety Reports For ${zoneName}`}
              <span className="new-safety-report-button" onClick={showSafetyReportForm}>
                ➕
              </span>
            </h2>
            <SafetyReportsTable
              onClickReport={onClickReport}
              opsUsers={opsUsers}
              reportTypes={reportTypes}
              resolutions={resolutions}
              safetyReports={safetyReports}
            />
          </Fragment>
        ))}
      <AlertDialog
        open={Boolean(documentToDelete)}
        title="Delete this document?"
        message="Are you sure you want to delete this document? This action cannot be undone."
        confirmLabel="Yes"
        cancelLabel="No"
        onConfirm={onConfirmDeleteDocument}
        onCancel={onConfirmCancelDocument}
      />
      {apiError && (
        <AlertDialog
          open={Boolean(documentToDelete)}
          title={apiError.title}
          message={apiError.message}
          cancelLabel="OK"
          onCancel={() => {
            setApiError(null);
            setDocumentToDelete(null);
          }}
        />
      )}
      <RazorLoader loaded={apiCallComplete} />
    </AuthenticatedPage>
  );
};

Safety.propTypes = {
  actions: PropTypes.object,
  admin: PropTypes.object,
  opsUsers: PropTypes.array,
  safetyReports: PropTypes.array,
  selectedZoneId: PropTypes.number,
  zones: PropTypes.array,
};
const mapStateToProps = (state) => {
  const {
    admin,
    safety_reports: safetyReports,
    selected_zone: selectedZoneId,
    users,
    zones,
  } = state;
  return {
    admin,
    zones: zones.zones.filter(({ active }) => active),
    opsUsers: users.ops.filter(({ is_active }) => is_active),
    safetyReports,
    selectedZoneId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getOpsUsers,
        getSafetyReports,
        setSelectedZone,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Safety);
